<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/produijie_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<div class="block60"></div>
		<div class="huaxue_projectdock_box relative w1240">
			<div class="itemobj relative" v-if="produceTypeList.length>0">
				<div class="titlename fontsize30">{{produceTypeList[0].name}}</div>
				<div class="text fontsize18">
					<p>{{produceTypeList[0].description}}</p>
				</div>
				<div class="btn1 fontsize14" @click="$util.routerPath('/proDockSchool')">查看详情</div>
				<div class="line"></div>
			</div>
			<div class="itemobj relative" v-if="produceTypeList.length>1">
				<div class="titlename fontsize30">{{produceTypeList[1].name}}</div>
				<div class="text fontsize18">
					<p>{{produceTypeList[1].description}}</p>
				</div>
				<div class="btn1 fontsize14" @click="$util.routerPath('/proDockExpert')">查看详情</div>
				<div class="line"></div>
			</div>
			<div class="itemobj relative" v-if="produceTypeList.length>2">
				<div class="titlename fontsize30">{{produceTypeList[2].name}}</div>
				<div class="text fontsize18">
					<p>{{produceTypeList[2].description}}</p>
				</div>
				<div class="btn1 fontsize14" @click="$util.routerPath('/proDockItem')">查看详情</div>
				<div class="line"></div>
			</div>
		</div>
		<!-- 科研院校 -->
		<div class="block60"></div>
		<div class="projectdock_kyyx_box">
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">科研院校</div>
				<div class="type_desc fontsize14">RESEARCH INSTITUTES</div>
			</div>
			<div class="huaxue_home_type w1239">
				<div class="title fontsize38"></div>
				<div class="typelist">
					<div class="typeobj fontsize22" @click="$util.routerPath('/proDockSchool')">更多</div>
				</div>
			</div>
			<div class="projectdock_siwper_box w1255">
				<div class="leftitem" style="height: 380px !important;">
					<img class="img" src="../assets/static/xmzyicon.png"/>
					<div class="textbox" v-if="produceTypeList.length>0">
						<img class="logo" :src="produceTypeList[0].imgUrl || require('../assets/static/xmzylx1.png')"/>
						<div class="text1 fontsize32">{{produceTypeList[0].name}}</div>
						<div class="text2 fontsize18">{{produceTypeList[0].extraObj}}</div>
					</div>
				</div>
				<div class="rightitem relative" v-if="swiperOptions.isshow">
					<div class="btn_prev" @click="slidePrev"><img src="../assets/static/icon_left_white.png"></div>
					<div class="btn_next" @click="slideNext"><img src="../assets/static/icon_right_white.png"></div>
					<div>
						<swiper ref="mySwiper" :options="swiperOptions">
						    <swiper-slide v-for="(item,index) in dataList" :key="index">
						    	<div class="slide_item" style="height: 380px !important;">
						    		<div class="img"><img :src="item.imageUrl || require('../assets/static/noimg.png')"></div>
									<div class="titlename fontsize20">{{item.collegeName}}</div>
									<div class="block20"></div>
									<div class="desc fontsize14"><span>机构属性：</span>{{item.collegeAttr}}</div>
									<div class="block5"></div>
									<div class="desc fontsize14"><span>重点研究领域：</span>{{item.collegeField}}</div>
						    	</div>
						    </swiper-slide>
						</swiper>
					</div>
				</div>
			</div>
		</div>
		<!-- 专家资源 -->
		<div class="block60"></div>
		<div class="projectdock_kyyx_box">
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">专家资源</div>
				<div class="type_desc fontsize14">EXPERT RESOURCES</div>
			</div>
			<div class="huaxue_home_type w1239">
				<div class="title fontsize38"></div>
				<div class="typelist">
					<div class="typeobj fontsize22" @click="$util.routerPath('/proDockExpert')">更多</div>
				</div>
			</div>
			<div class="projectdock_siwper_box w1255">
				<div class="leftitem">
					<img class="img" src="../assets/static/xmzyicon.png"/>
					<div class="textbox" v-if="produceTypeList.length>1">
						<img class="logo" :src="produceTypeList[1].imgUrl || require('../assets/static/xmzylx2.png')"/>
						<div class="text1 fontsize32">{{produceTypeList[1].name}}</div>
						<div class="text2 fontsize18">{{produceTypeList[1].extraObj}}</div>
					</div>
				</div>
				<div class="rightitem relative" v-if="swiperOptions1.isshow">
					<div class="btn_prev" @click="slidePrev1"><img src="../assets/static/icon_left_white.png"></div>
					<div class="btn_next" @click="slideNext1"><img src="../assets/static/icon_right_white.png"></div>
					<div>
						<swiper ref="mySwiper1" :options="swiperOptions1">
						    <swiper-slide v-for="(item,index) in dataList2" :key="index">
						    	<div class="slide_item" @click="$util.routerPath('/teamDetail',{uid:item.id})">
						    		<div class="img"><img :src="item.imgUrl || require('../assets/static/logo.png')"></div>
									<div class="block10"></div>
									<div class="titlename fontsize20">{{item.title}}</div>
									<div class="block20"></div>
									<div class="desc fontsize14 clamp4"><span>擅长领域：</span>{{item.description}}</div>
						    	</div>
						    </swiper-slide>
						</swiper>
					</div>
				</div>
			</div>
		</div>
		<!-- 项目资源 -->
		<div class="block60"></div>
		<div class="projectdock_kyyx_box">
			<div class="huaxue_new_type_box w1239">
				<div class="type_name fontsize38">项目资源</div>
				<div class="type_desc fontsize14">PROJECT RESOURCES</div>
			</div>
			<div class="huaxue_home_type w1239">
				<div class="title fontsize38"></div>
				<div class="typelist">
					<div :class="{'hover':successFlag==0}" class="typeobj fontsize22" @click="handleProType(0)">项目成果</div>
					<div :class="{'hover':successFlag==1}" class="typeobj fontsize22" @click="handleProType(1)">技术需求</div>
					<div v-if="successFlag==0" class="typeobj fontsize22" @click="$util.routerPath('/proDockItem')">更多</div>
					<div v-if="successFlag==1" class="typeobj fontsize22" @click="$util.routerPath('/proDockTech')">更多</div>
				</div>
			</div>
			<div class="projectdock_siwper_box w1255">
				<div class="leftitem">
					<img class="img" src="../assets/static/xmzyicon.png"/>
					<div class="textbox" v-if="produceTypeList.length>2">
						<img class="logo" :src="produceTypeList[2].imgUrl || require('../assets/static/xmzylx3.png')"/>
						<div class="text1 fontsize32">{{produceTypeList[2].name}}</div>
						<div class="text2 fontsize18">{{produceTypeList[2].extraObj}}</div>
					</div>
				</div>
				<div class="rightitem relative" v-if="swiperOptions2.isshow">
					<div class="btn_prev" @click="slidePrev2"><img src="../assets/static/icon_left_white.png"></div>
					<div class="btn_next" @click="slideNext2"><img src="../assets/static/icon_right_white.png"></div>
					<div>
						<swiper ref="mySwiper2" :options="swiperOptions2">
						    <swiper-slide v-for="(item,index) in dataList3" :key="index">
						    	<div class="slide_item" @click="$util.routerPath('/proDockDetail',{uid:item.id})">
									<div class="block10"></div>
						    		<div class="img"><img :src="item.imgUrl || require('../assets/static/noimg.png')"></div>
									<div class="block20"></div>
									<div class="titlename fontsize18 clamp2">{{item.title}}</div>
									<div class="block10"></div>
									<div class="descdiv fontsize12">
										<div class="text">{{item.industryType}}</div>
										<div class="text" v-if="successFlag==1">{{item.demandType}}</div>
										<div class="text" v-if="successFlag==1">{{item.endDate}}</div>
										<div class="text" v-if="successFlag==0">{{item.maturity}}</div>
										<div class="text" v-if="successFlag==0">{{item.payType}}</div>
										<div class="text" v-if="successFlag==0">{{item.technologyType}}</div>
									</div>
						    	</div>
						    </swiper-slide>
						</swiper>
					</div>
				</div>
			</div>
		</div>
		
		
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
//页面引入swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
	components: {
	    swiper,
	    swiperSlide
	},
	data() {
		return {
			dataList:[],
			dataList2:[],
			dataList3:[],
			currentPage:1,
			pageSize:10,
			total:0,
			swiperOptions:{
				isshow:false,
				slidesPerView: 4,
			},
			swiperOptions1:{
				isshow:false,
				slidesPerView: 4,
			},
			swiperOptions2:{
				isshow:false,
				slidesPerView: 4,
			},
			successFlag:0,//项目类型 0项目成果 1技术需求
			bannerObj:{},
			produceTypeList:[],//类型
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(15).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		if(document.body.clientWidth<750){
			//手机端
			this.swiperOptions.slidesPerView = 1
			this.swiperOptions1.slidesPerView = 1
			this.swiperOptions2.slidesPerView = 1
		}
		this.swiperOptions.isshow = true
		this.swiperOptions1.isshow = true
		this.swiperOptions2.isshow = true
		//获取三大类型
		this.getZiangmuduijieleixing()
		//获取(科研院校)
		this.getPatentCollege()
		//获取(文章专家)
		this.getZhuanjiatuandui()
		//获取(项目资源)
		this.getProjectResource()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//类型
		handleProType(type){
			this.successFlag = type
			//获取(项目资源)
			this.getProjectResource()
		},
		//上一页
		slidePrev() {
		    this.$refs.mySwiper.swiper.slidePrev();
		},
		//下一页
		slideNext() {
		    this.$refs.mySwiper.swiper.slideNext()
		},
		//上一页
		slidePrev1() {
		    this.$refs.mySwiper1.swiper.slidePrev();
		},
		//下一页
		slideNext1() {
		    this.$refs.mySwiper1.swiper.slideNext()
		},
		//上一页
		slidePrev2() {
		    this.$refs.mySwiper2.swiper.slidePrev();
		},
		//下一页
		slideNext2() {
		    this.$refs.mySwiper2.swiper.slideNext()
		},
		//获取类型
		getZiangmuduijieleixing() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 5
			};
			this.$http.post('frontEnd/ProjectResource/getZiangmuduijieleixing', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.produceTypeList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取(科研院校)
		getPatentCollege() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 12,
			};
			this.$http.post('frontEnd/ProjectResource/getPatentCollege', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取(文章专家)
		getZhuanjiatuandui() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 12,
			};
			this.$http.post('frontEnd/ProjectResource/getZhuanjiatuandui', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.dataList2 = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取(项目资源)
		getProjectResource() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 12,
				successFlag:this.successFlag
			};
			this.$http.post('frontEnd/ProjectResource/getProjectResource', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item, index) => {
						//console.log(item)
						if(item.imagesList&&item.imagesList!='[]'){
							try{
								item["imgUrl"] = JSON.parse(item.imagesList)[0]
							}catch(e){
								//TODO handle the exception
							}
						}
					})
					_this.dataList3 = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
